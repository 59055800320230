import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "installation"
    }}>{`Installation`}</h1>
    <h2 {...{
      "id": "getting-started"
    }}>{`Getting started`}</h2>
    <p>{`This module is distributed via `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/"
      }}>{`npm`}</a>{` which is bundled with
`}<a parentName="p" {...{
        "href": "https://nodejs.org"
      }}>{`node`}</a>{` and should be installed as one of your project's `}<inlineCode parentName="p">{`devDependencies`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`# if you're using npm
npm install --save-dev @testing-library/react-hooks
# if you're using yarn
yarn add --dev @testing-library/react-hooks
`}</code></pre>
    <h3 {...{
      "id": "peer-dependencies"
    }}>{`Peer dependencies`}</h3>
    <p><inlineCode parentName="p">{`react-hooks-testing-library`}</inlineCode>{` does not come bundled with a version of
`}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/react"
      }}><inlineCode parentName="a">{`react`}</inlineCode></a>{` to allow you to install the specific version you want
to test against. It also does not come installed with a specific renderer, we currently support
`}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/react-test-renderer"
      }}><inlineCode parentName="a">{`react-test-renderer`}</inlineCode></a>{` and
`}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/react-dom"
      }}><inlineCode parentName="a">{`react-dom`}</inlineCode></a>{`, you only need to install one of them. For
more information see `}<a parentName="p" {...{
        "href": "/installation#renderer"
      }}>{`Renderer`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install react@^16.9.0
npm install --save-dev react-test-renderer@^16.9.0
`}</code></pre>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`NOTE: The minimum supported version of `}<inlineCode parentName="strong">{`react`}</inlineCode>{`, `}<inlineCode parentName="strong">{`react-test-renderer`}</inlineCode>{` and `}<inlineCode parentName="strong">{`react-dom`}</inlineCode>{` is
`}<inlineCode parentName="strong">{`^16.9.0`}</inlineCode>{`.`}</strong></p>
    </blockquote>
    <h2 {...{
      "id": "renderer"
    }}>{`Renderer`}</h2>
    <p>{`When running tests, a renderer is required in order to render the React component we wrap around
your hook. We currently support two different renderers:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`react-test-renderer`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`react-dom`}</inlineCode></li>
    </ul>
    <p>{`When using standard import for this library (show below), we will attempt to auto-detect which
renderer you have installed and use it without needing any specific wiring up to make it happen. If
you have both installed in your project, and you use the standard import (see below) the library
will default to using `}<inlineCode parentName="p">{`react-test-renderer`}</inlineCode>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`We use `}<inlineCode parentName="p">{`react-test-renderer`}</inlineCode>{` by default as it enables hooks to be tested that are designed for
either `}<inlineCode parentName="p">{`react`}</inlineCode>{` or `}<inlineCode parentName="p">{`react-native`}</inlineCode>{` and it is compatible with more test runners out-of-the-box as
there is no DOM requirement to use it.`}</p>
    </blockquote>
    <p>{`The standard import looks like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderHook } from '@testing-library/react-hooks'
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Note: The auto detection function may not work if tests are being bundled (e.g. to be run in a
browser)`}</p>
    </blockquote>
    <h3 {...{
      "id": "act"
    }}>{`Act`}</h3>
    <p>{`Each render also provides a unique `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/test-utils.html#act"
      }}><inlineCode parentName="a">{`act`}</inlineCode>{` function`}</a>{`
that cannot be used with other renderers. In order to simplify with `}<inlineCode parentName="p">{`act `}</inlineCode>{`function you need to use,
we also export the correct one alongside the detected renderer for you:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderHook, act } from '@testing-library/react-hooks'
`}</code></pre>
    <h2 {...{
      "id": "being-specific"
    }}>{`Being specific`}</h2>
    <p>{`Auto-detection is great for simplifying setup and getting out of your way, but sometimes you do need
a little but more control. If a test needs requires a specific type of environment, the import can
be appended to force a specific renderer to be use. The supported environments are:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`dom`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`native`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`server`}</inlineCode></li>
    </ul>
    <p>{`The imports for each type of renderer are as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { renderHook, act } from '@testing-library/react-hooks' // will attempt to auto-detect

import { renderHook, act } from '@testing-library/react-hooks/dom' // will use react-dom

import { renderHook, act } from '@testing-library/react-hooks/native' // will use react-test-renderer

import { renderHook, act } from '@testing-library/react-hooks/server' // will use react-dom/server
`}</code></pre>
    <h2 {...{
      "id": "pure-imports"
    }}>{`Pure imports`}</h2>
    <p>{`Importing from any of the previously mentioned imports will cause some side effects in the test
environment:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`cleanup`}</inlineCode>{` is automatically called in an `}<inlineCode parentName="li">{`afterEach`}</inlineCode>{` block`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`console.error`}</inlineCode>{` is patched to hide some React errors`}</li>
    </ol>
    <p>{`The specifics of these side effects are discussed in more detail in the
`}<a parentName="p" {...{
        "href": "/reference/api"
      }}>{`API reference`}</a>{`.`}</p>
    <p>{`If you want to ensure the imports are free of side-effects, you can use the `}<inlineCode parentName="p">{`pure`}</inlineCode>{` imports instead,
which can be accessed by appending `}<inlineCode parentName="p">{`/pure`}</inlineCode>{` to the end of any of the other imports:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { renderHook, act } from '@testing-library/react-hooks/pure'

import { renderHook, act } from '@testing-library/react-hooks/dom/pure'

import { renderHook, act } from '@testing-library/react-hooks/native/pure'

import { renderHook, act } from '@testing-library/react-hooks/server/pure'
`}</code></pre>
    <h2 {...{
      "id": "testing-framework"
    }}>{`Testing framework`}</h2>
    <p>{`In order to run tests, you will probably want to be using a test framework. If you have not already
got one, we recommend using `}<a parentName="p" {...{
        "href": "https://jestjs.io/"
      }}>{`Jest`}</a>{`, but this library should work without issues
with any of the alternatives.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      